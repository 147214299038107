import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Tooltip } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { EDITABLE_ACTIVITY_PROPERTIES } = ActivityEvents;

const PREV_VALUE_PROP_NAMES = Object.fromEntries(
  EDITABLE_ACTIVITY_PROPERTIES.map(({ propName, prevPropName }) => [propName, prevPropName])
);

const ExpandedListItem = (props) => {
  const {
    header,
    type,
    item: {
      originalName,
      expense,
      amount,
      date,
      description,
      ...restItem
    } = {},
    shownColumnNames = [],
    possibleColumnCount = 0,
    descriptionColumnShown
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [tooltipOpened, setTooltipOpened] = useState({});

  const descColStyle = useMemo(() => {
    const colSpan = 1 + possibleColumnCount - shownColumnNames.length;

    return { width: `${colSpan}px`, flex: colSpan };
  }, [possibleColumnCount, shownColumnNames]);

  const dateValue = useMemo(() => {
    return date && moment.utc(date).format(Constants.DATETIME_FORMATS.DATE_TEXT);
  }, [date]);

  const handleMouseEnter = useCallback(({ currentTarget: { dataset: { name } } }) => {
    setTooltipOpened((prev) => ({ ...prev, [name]: true }));
  }, []);

  const handleMouseLeave = useCallback(({ currentTarget: { dataset: { name } } }) => {
    setTooltipOpened((prev) => ({ ...prev, [name]: false }));
  }, []);

  if (header) {
    return (
      <div className={classNames(Css.expandedListItem, Css.header)}>
        <div className={Css.amount}>
          <div><div>{uiTexts.amount}</div></div>
        </div>
        <div className={Css.date}>
          <div><div>{uiTexts.date}</div></div>
        </div>
        {shownColumnNames.map((keys) => (
          <div key={keys[0]} name={keys[0]}>
            <div><div>{keys.map((key) => uiTexts[key]).join(" / ")}</div></div>
          </div>
        ))}
        <div className={Css.description} style={descColStyle}>
          <div><div>{descriptionColumnShown && uiTexts.description}</div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={Css.expandedListItem}>
      {type === ActivityEvents.TYPES.DOCUMENT_RECOGNITION_FAILED
        ? <div className={Css.description} title={originalName}>
          <div><div>{originalName}</div></div>
        </div>
        : (
          <>
            <div className={Css.amount}>
              <div>
                <div className={Css.label}>{uiTexts.amount}:</div>
                <div
                  title={amount || ""}
                  className={classNames(CommonCss.coloredNumber, Css.fontWeight)}
                  data-negative={expense ? "" : undefined}>{amount || Constants.EMPTY_PLACEHOLDER}</div>
              </div>
            </div>
            <div className={Css.date} data-empty={!date || undefined}>
              <div>
                <div className={Css.label}>{uiTexts.date}:</div>
                <div title={dateValue || ""}>{dateValue || Constants.EMPTY_PLACEHOLDER}</div>
              </div>
            </div>
            {shownColumnNames.map((keys) => {
              const [keyA, keyB, keyC] = keys;

              const key = restItem[keyC] ? keyC : (restItem[keyB] ? keyB : keyA);

              const value = restItem[key];

              if (!key) return null;

              const prevValue = restItem[PREV_VALUE_PROP_NAMES[key]];

              const icon = prevValue === "" && value
                ? <Icons.Plus weight="bold" className={classNames(Css.icon, Css.plus)} />
                : (!!prevValue && (
                  value === ""
                    ? <Icons.X weight="bold" className={classNames(Css.icon, Css.minus)} />
                    : <Icons.ArrowsClockwise weight="bold" className={Css.icon} />
                ));

              return (
                <div
                  key={key}
                  name={key}
                  data-name={key}
                  data-empty={(!value && !prevValue) || undefined}
                  onMouseEnter={Utils.checkIsTouchDevice() ? undefined : handleMouseEnter}
                  onMouseLeave={Utils.checkIsTouchDevice() ? undefined : handleMouseLeave}>
                  {icon}
                  {!Utils.checkIsTouchDevice() && (prevValue || (prevValue === "" && value)) && (
                    <Tooltip
                      opened={tooltipOpened[key]}
                      className={Css.tooltip}
                      contentClassName={Css.tooltipContent}>
                      {value ? <span><b>{prevValue}</b></span> : null}
                      {prevValue && value ? <Icons.ArrowRight weight="bold" /> : null}
                      {prevValue ? <span><b>{value}</b></span> : null}
                      {prevValue === "" ? <b>{uiTexts.valueWasAdded}</b> : null}
                      {value === "" ? <b>{uiTexts.valueWasCleared}</b> : null}
                    </Tooltip>
                  )}
                  <div>
                    <div className={Css.label}>
                      {keys.map((item) => uiTexts[item]).join(" / ")}:
                    </div>
                    {icon}
                    <div className={classNames(!value && Css.neutralColor)}>
                      {prevValue || (prevValue === "")
                        ? (
                          !value && prevValue
                            ? <b title={prevValue}><s>{prevValue}</s></b>
                            : <b title={value || ""}>{value || Constants.EMPTY_PLACEHOLDER}</b>
                        )
                        : <span title={value || ""}>{value || Constants.EMPTY_PLACEHOLDER}</span>}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className={Css.description}
              style={descColStyle}
              data-empty={!description || undefined}>
              <div>
                <div className={Css.label}>{uiTexts.description}:</div>
                <div
                  title={(descriptionColumnShown && description) || ""}
                  className={classNames(!description && Css.neutralColor)}>
                  {descriptionColumnShown && (description || Constants.EMPTY_PLACEHOLDER)}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default React.memo(ExpandedListItem);
